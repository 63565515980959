'use client';

import React, { Suspense, useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';

import LoadingSpinner from '../components/common/LoadingSpinner';
import ErrorPage from '../components/maintenance/Error';

interface GlobalErrorProps {
  error: Error;
}

export default function GlobalError({ error }: GlobalErrorProps) {
  useEffect(() => {
    // Log the error to Sentry
    const sendError = async () => {
      Sentry.captureException(error, {
        extra: {
          url: window.location.href,
        },
      });
    };
    sendError();
  }, [error]);

  return (
    <div className="flex flex-col items-stretch px-4 sm:px-8 max-w-full overflow-hidden bg-sivarbet-secondary-bg/30">
      <Suspense fallback={<LoadingSpinner />}>
        <ErrorPage />
      </Suspense>
    </div>
  );
}
