'use client';

import Head from 'next/head';
import Image from 'next/image';

import logo from '@/public/img/logo/hondubetlogo.png';

const ErrorPage: React.FC = () => {
  return (
    <div className="h-screen flex flex-col justify-center items-center font-sans overflow-hidden">
      <Head>
        <title>Error</title>
      </Head>
      <div>
        <Image src={logo} alt="Logo de Hondubet" width={300} height={100} />
      </div>
      <h1 className="text-2xl text-white my-4 text-center">
        ¡Lo sentimos! Ocurrió un error.
      </h1>
      <p className="text-lg pb-20 max-w-3/4 text-center leading-6 text-white">
        Ocurrió un error. Por favor, intenta más tarde o contacta al soporte.
      </p>
    </div>
  );
};

export default ErrorPage;
